import React from "react";
import HelmetWrapper from "../components/helmet-wrapper";
import Layout from "../components/layout";
import CountupClock from "../components/since/countup-clock";

const Since = ({ location }) => {
  return (
    <Layout
      location={location}
      killPadding={true}
      background={"bg-theme2-bg-1"}
    >
      <HelmetWrapper pageTitle="Since" />
      <div className="since bg-theme2-bg-1 p-4 md:max-w-6xl mx-auto my-20 md:my-48">
        <div className="w-fit mx-auto md:min-h-[528px] lg:min-h-[352px]">
          <CountupClock />
        </div>
      </div>
    </Layout>
  );
};

export default Since;
